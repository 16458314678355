window.LOAD = {
    init: function () {
        $.each(window.load_after.js, function (index, url) {
            var script = document.createElement('script');
            script.src = url;
            script.defer = true;
            script.setAttribute('defer','');
            document.getElementsByTagName('body')[0].appendChild(script);
        });

        $.each(window.load_after.fonts, function (index, url) {

            var link = document.createElement('link');
            link.href = url;
            link.rel = "stylesheet";
            document.getElementsByTagName('body')[0].appendChild(link);
        });

        $.each(window.load_after.css, function (index, url) {

            var link = document.createElement('link');
            link.href = url;
            link.rel = "stylesheet";
            document.getElementsByTagName('body')[0].appendChild(link);
        });
    }
};

$(document).ready(function () {
    window.LOAD.init();

    $('.menu-map-btn').click(function () {
        $('#map #gmap_block .btn').click();
    });

    $('#map #gmap_block .btn').click(function () {
        if(undefined == $(this).attr('loaded')) {

            $.each(window.load_after.gmap, function (index, url) {
                var script = document.createElement('script');
                script.src = url;
                script.defer = true;
                script.setAttribute('defer','');
                document.getElementsByTagName('body')[0].appendChild(script);
            });

            $(this).attr('loaded', true);
        }
    });
});