window.jQuery = $;
window.$ = $;

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

/* settings */
var FIRST_DATA = atob($('meta[name="settings"]').attr('content'));
FIRST_DATA = JSON.parse(FIRST_DATA);
window.main_color = FIRST_DATA.main_color;
window.gmap_img = FIRST_DATA.gmap_img;
window.chargers = FIRST_DATA.chargers;
window.load_after = FIRST_DATA.load_after;
window.map_center = FIRST_DATA.map_center;
/* end settings */


window.getUrlParameter = function (sParam) {
    var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return typeof sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
    return false;
};

window.get_url_domain = function(data) {
    var    a      = document.createElement('a');
    a.href = data;
    return a.hostname;
};


$(document).ready(function () {
    window.source = {
        check_get: function () {

            var utm_source = window.getUrlParameter('utm_source');
            var utm_source_coockie = window.JS_COOKIES.get_data('utm_source');

            if(utm_source != false && utm_source_coockie == null) {

                window.JS_COOKIES.set_data('utm_source', utm_source, 365);

            } else if(utm_source == false && typeof utm_source_coockie == 'undefined') {

                if(document.referrer != '') {
                    var domain = window.get_url_domain(document.referrer);
                    window.JS_COOKIES.set_data('utm_source', domain, 365);
                }
            }
        }
    };
    window.source.check_get();
});